const {
	href, origin, pathname, search
} = window.location;


const redirects = new Map([
	["/de/residenz-am-balmer-see-bs_12", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS12"],
	["/de/10-bernsteinquartier-bg1186", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1186"],
	["/de/01-ahlbeck-ferienhaus-ahlbeck-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Ahlbeck-.Ferienhaus"],
	["/de/01-ahlbeck-ferienhaus-kieferngrund-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Kieferngrund-.Haus"],
	["/de/01-ahlbeck-ferienwohnung-fast", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Fast"],
	["/de/01-ahlbeck-ferienwohnung-kieferngrund", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Kieferngrund-.FeWo"],
	["/de/01-ahlbeck-ferienwohnung-kira", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Kira"],
	["/de/01-ahlbeck-gartenoase-01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Gartenoase"],
	["/de/01-ahlbeck-haus-meerblick-whg-04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.04"],
	["/de/01-ahlbeck-haus-meerblick-whg-10", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.10"],
	["/de/01-ahlbeck-haus-meerblick-whg-12", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.12"],
	["/de/01-ahlbeck-haus-meerblick-whg-14", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.14"],
	["/de/01-ahlbeck-haus-meerblick-whg-15", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.15"],
	["/de/01-ahlbeck-haus-meerblick-whg-17", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.17"],
	["/de/01-ahlbeck-lieblingssuite-am-strand", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Lieblingssuite"],
	["/de/01-ahlbeck-lutt-matt-n", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Luett-Mattn"],
	["/de/01-ahlbeck-villa-ahlbeck-whg-kuhlungsborn", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Villa.Ahlbeck-.Kuehlungsborn"],
	["/de/01-ahlbeck-villa-ahlbeck-whg-warnemunde", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Villa.Ahlbeck-.Warnemuende"],
	["/de/01-ahlbeck-villa-bellevue-app-8", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bellevue-.08"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-alma", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Alma"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-clara", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Clara"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-elfriede", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Elfriede"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-gertrud", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Gertrud"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-helene", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Helene"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-wanda", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Wanda"],
	["/de/01-ahlbeck-villa-germania-fewo-5", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Germania-.05"],
	["/de/02-heringsdorf-am-sonnenblick-whg-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.01"],
	["/de/02-heringsdorf-am-sonnenblick-whg-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.02"],
	["/de/02-heringsdorf-am-sonnenblick-whg-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.03"],
	["/de/02-heringsdorf-am-sonnenblick-whg-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.04"],
	["/de/02-heringsdorf-am-sonnenblick-whg-5", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.05"],
	["/de/02-heringsdorf-am-sonnenblick-whg-6", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.06"],
	["/de/02-heringsdorf-am-sonnengarten-whg-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.01"],
	["/de/02-heringsdorf-am-sonnengarten-whg-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.02"],
	["/de/02-heringsdorf-am-sonnengarten-whg-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.03"],
	["/de/02-heringsdorf-am-sonnengarten-whg-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.04"],
	["/de/02-heringsdorf-delbruck-villen-111", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Delbrueck.Villen-.111"],
	["/de/02-heringsdorf-ferienwohnung-beier-oben", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Beier.2-.oben"],
	["/de/02-heringsdorf-ferienwohnung-beier-unten", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Beier.1-.unten"],
	["/de/02-heringsdorf-ferienwohnung-johanna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Johanna"],
	["/de/02-heringsdorf-ferienwohnung-marie-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marie-.01"],
	["/de/02-heringsdorf-ferienwohnung-marie-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marie-.02"],
	["/de/02-heringsdorf-ferienwohnung-marie-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marie-.03"],
	["/de/02-heringsdorf-ferienwohnung-sonnenkinder", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenkinder"],
	["/de/02-heringsdorf-godewind", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Godewind"],
	["/de/02-heringsdorf-haus-henny-mit-sauna-und-kamin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Henny"],
	["/de/02-heringsdorf-ostseeliebe-mit-infrarot-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Ostseeliebe-.FeWo"],
	["/de/02-heringsdorf-residenz-bellevue", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Residenz.Bellevue-.07"],
	["/de/02-heringsdorf-strandoase-whg-21", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Strandoase-.21"],
	["/de/02-heringsdorf-sunset", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sunset"],
	["/de/02-heringsdorf-villa-emmi", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Villa.Emmi-.08"],
	["/de/02-heringsdorf-villa-inge-whg-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Inge-.04"],
	["/de/02-heringsdorf-villa-inge-whg-6", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Inge-.06"],
	["/de/02-heringsdorf-villa-marfa-app-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marfa-.03"],
	["/de/02-heringsdorf-villa-minheim-whg-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Minheim-.04"],
	["/de/02-heringsdorf-villen-am-goethepark-villa-christiane-whg-8", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Christiane-.08"],
	["/de/02-heringsdorf-waterfront-captain-s-haus-1-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.1-2"],
	["/de/02-heringsdorf-waterfront-galeone-whg-4-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.4-2"],
	["/de/02-heringsdorf-waterfront-galeone-whg-40-bernsteinkajute", "/s"],
	["/de/02-heringsdorf-waterfront-karavelle-whg-3-0", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.3-0"],
	["/de/02-heringsdorf-waterfront-karavelle-whg-3-5", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.3-5"],
	["/de/03-bansin_schloonseeapartements-sa_04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.04"],
	["/de/03-bansin_schloonseeapartements-sa_05", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.05"],
	["/de/03-bansin_schloonseeapartements-sa_06", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.06"],
	["/de/03-bansin_schloonseeapartements-sa_07", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.07"],
	["/de/03-bansin_schloonseeapartements-sa_10", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.10"],
	["/de/03-bansin_schloonseeapartements-sa_20", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.20"],
	["/de/03-bansin-ferienhaus-mona", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Mona"],
	["/de/03-bansin-ferienhaus-renate-wohnung-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.01"],
	["/de/03-bansin-ferienhaus-renate-wohnung-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.02"],
	["/de/03-bansin-ferienhaus-renate-wohnung-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.03"],
	["/de/03-bansin-ferienhaus-renate-wohnung-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.04"],
	["/de/03-bansin-ferienhaus-thorina", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Thorina"],
	["/de/03-bansin-golz-am-see-01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.01"],
	["/de/03-bansin-golz-am-see-02", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.02"],
	["/de/03-bansin-golz-am-see-03", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.03"],
	["/de/03-bansin-golz-am-see-04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.04"],
	["/de/03-bansin-golz-am-see-05", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.05"],
	["/de/03-bansin-golz-am-see-06", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.06"],
	["/de/03-bansin-haus-auguste-app-16", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Haus.Auguste-.16"],
	["/de/03-bansin-haus-barnheide-wohnung-sandburg", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Barnheide-.Sandburg"],
	["/de/03-bansin-haus-barnheide-wohnung-silbermowe", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Barnheide-.Silbermoewe"],
	["/de/03-bansin-haus-barnheide-wohnung-strandkorb", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Barnheide-.Strandkorb"],
	["/de/03-bansin-haus-rolandseck-whg-03", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Rolandseck-.03"],
	["/de/03-bansin-haus-rolandseck-whg-06", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Rolandseck-.06"],
	["/de/03-bansin-haus-sternstunden", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Sternstunden"],
	["/de/03-bansin-haus-wellenreiter", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Wellenreiter"],
	["/de/03-bansin-schloonseevillen-sv_13", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.13"],
	["/de/03-bansin-schloonseevillen-sv_17", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.17"],
	["/de/03-bansin-schloonseevillen-sv_19", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.19"],
	["/de/03-bansin-schloonseevillen-sv_25", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.25"],
	["/de/03-bansin-seehof-whg-1-kleiner", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seehof.-kleiner--.01"],
	["/de/03-bansin-seehof-wohnung-1-33", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seehof-.1-33"],
	["/de/03-bansin-seepark-1112", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seepark-.703"],
	["/de/03-bansin-seepark-703-apartment-traful", "/s"],
	["/de/03-bansin-seepark-704", "/s"],
	["/de/03-bansin-seepark-705", "/s"],
	["/de/03-bansin-seepark-808", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seepark-.808"],
	["/de/03-bansin-villa-anna-whg-amethyst", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Anna-.Amethyst"],
	["/de/03-bansin-villa-anna-whg-bernstein", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Anna-.Bernstein"],
	["/de/03-bansin-villa-anna-whg-rubin", "/s"],
	["/de/03-bansin-villa-cremona-fewo-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.01"],
	["/de/03-bansin-villa-cremona-fewo-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.02"],
	["/de/03-bansin-villa-cremona-fewo-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.03"],
	["/de/03-bansin-villa-cremona-fewo-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.04"],
	["/de/03-bansin-villa-exss-whg-harmonie", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Exss-.Harmonie"],
	["/de/03-bansin-villa-frieda-whg-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.01"],
	["/de/03-bansin-villa-frieda-whg-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.02"],
	["/de/03-bansin-villa-frieda-whg-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.04"],
	["/de/03-bansin-villa-frieda-whg-6", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.06"],
	["/de/03-bansin-villa-frieda-whg-8", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.08"],
	["/de/03-bansin-villa-frisia-whg-31", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frisia-.31"],
	["/de/03-bansin-villa-frohsinn-whg-7", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frohsinn-.07"],
	["/de/03-bansin-villa-malve-wohnung-7", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Malve-.07"],
	["/de/03-bansin-villa-malve-wohnung-8", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Malve-.08"],
	["/de/03-bansin-villa-malve-wohnung-9", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Malve-.09"],
	["/de/03-bansin-villa-margot-01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Margot-.01"],
	["/de/03-bansin-villa-margot-02", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Margot-.02"],
	["/de/03-bansin-villa-margot-10", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Margot-.10"],
	["/de/03-bansin-villa-strandperle-whg-28-29", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Strandperle-.Whg-.28-29"],
	["/de/03-bansin-villa-waldstrasse-app-14", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Waldstrasse-.14"],
	["/de/04-gorki-park-gp_02", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP02"],
	["/de/04-gorki-park-gp_03", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP03"],
	["/de/04-gorki-park-gp_04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP04"],
	["/de/04-gorki-park-gp_05", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP05"],
	["/de/04-gorki-park-gp_06", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP06"],
	["/de/04-gorki-park-gp_07", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP07"],
	["/de/04-gorki-park-gp_08", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP08"],
	["/de/04-gorki-park-gp_09", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP09"],
	["/de/04-gorki-park-gp_10", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP10"],
	["/de/04-gorki-park-gp_11", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP11"],
	["/de/04-gorki-park-gp_12", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP12"],
	["/de/04-gorki-park-gp_13", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP13"],
	["/de/04-gorki-park-gp_16", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP16"],
	["/de/04-gorki-park-gp_17", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP17"],
	["/de/04-gorki-park-gp_18", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP18"],
	["/de/04-gorki-park-gp_19", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP19"],
	["/de/04-gorki-park-gp_20", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP20"],
	["/de/04-gorki-park-gp_23", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP23"],
	["/de/04-gorki-park-gp_24", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP24"],
	["/de/05-residenz-am-balmer-see-bs_01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS01"],
	["/de/05-residenz-am-balmer-see-bs_02", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS02"],
	["/de/05-residenz-am-balmer-see-bs_03", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS03"],
	["/de/05-residenz-am-balmer-see-bs_04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS04"],
	["/de/05-residenz-am-balmer-see-bs_05", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS05"],
	["/de/05-residenz-am-balmer-see-bs_06", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS06"],
	["/de/05-residenz-am-balmer-see-bs_07", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS07"],
	["/de/05-residenz-am-balmer-see-bs_08", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS08"],
	["/de/05-residenz-am-balmer-see-bs_09", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS09"],
	["/de/05-residenz-am-balmer-see-bs_10", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS10"],
	["/de/05-residenz-am-balmer-see-bs_11", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS11"],
	["/de/05-residenz-am-balmer-see-bs_12", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS12"],
	["/de/05-residenz-am-balmer-see-bs_13", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS13"],
	["/de/05-residenz-am-balmer-see-bs_14", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS14"],
	["/de/05-residenz-am-balmer-see-bs_15", "/s#/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm"],
	["/de/05-residenz-am-balmer-see-bs_16", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS16"],
	["/de/05-residenz-am-balmer-see-bs_18", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS18"],
	["/de/05-residenz-am-balmer-see-bs_19", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS19"],
	["/de/05-residenz-am-balmer-see-bs_20", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS20"],
	["/de/05-residenz-am-balmer-see-bs_21-kleine-auszeit", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS21"],
	["/de/05-residenz-am-balmer-see-bs_22", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS22"],
	["/de/05-residenz-am-balmer-see-bs_23", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS23"],
	["/de/05-residenz-am-balmer-see-bs_25", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS25"],
	["/de/05-residenz-am-balmer-see-bs_26", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS26"],
	["/de/05-residenz-am-balmer-see-bs_29", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS29"],
	["/de/05-residenz-am-balmer-see-bs_30", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS30"],
	["/de/05-residenz-am-balmer-see-bs_31", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS31"],
	["/de/05-residenz-am-balmer-see-bs_32", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS32"],
	["/de/05-residenz-am-balmer-see-bs_33", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS33"],
	["/de/05-residenz-am-balmer-see-bs_34", "/s#/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm"],
	["/de/05-residenz-am-balmer-see-bs_35", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS35"],
	["/de/05-residenz-am-balmer-see-bs_36", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS36"],
	["/de/05-residenz-am-balmer-see-bs_37", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS37"],
	["/de/05-residenz-am-balmer-see-bs_38", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS38"],
	["/de/05-residenz-am-balmer-see-bs_39", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS39"],
	["/de/05-residenz-am-balmer-see-bs_40", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS40"],
	["/de/05-residenz-am-balmer-see-bs_41", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS41"],
	["/de/05-residenz-am-balmer-see-bs_42", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS42"],
	["/de/05-residenz-am-balmer-see-bs_43", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS43"],
	["/de/05-residenz-am-balmer-see-bs_44", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS44"],
	["/de/05-residenz-am-balmer-see-bs_45", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS45"],
	["/de/05-residenz-am-balmer-see-bs_46", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS46"],
	["/de/05-residenz-am-balmer-see-bs_47", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS47"],
	["/de/05-residenz-am-balmer-see-bs_48", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS48"],
	["/de/05-residenz-am-balmer-see-bs_49", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS49"],
	["/de/05-residenz-am-balmer-see-bs_50", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS50"],
	["/de/05-residenz-am-balmer-see-bs_51", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS51"],
	["/de/05-residenz-am-balmer-see-bs_52", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS52"],
	["/de/05-residenz-am-balmer-see-bs_53", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS53"],
	["/de/05-residenz-am-balmer-see-bs_54", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS54"],
	["/de/05-residenz-am-balmer-see-bs_55", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS55"],
	["/de/05-residenz-am-balmer-see-bs_56", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS56"],
	["/de/05-residenz-am-balmer-see-bs_57", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS57"],
	["/de/05-residenz-am-balmer-see-bs_58", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS58"],
	["/de/05-residenz-am-balmer-see-bs_60", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS60"],
	["/de/05-residenz-am-balmer-see-bs_61", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS61"],
	["/de/05-residenz-am-balmer-see-bs_62", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS62"],
	["/de/05-residenz-am-balmer-see-bs_63", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS63"],
	["/de/05-residenz-am-balmer-see-bs_65", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS65"],
	["/de/05-residenz-am-balmer-see-bs_66", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS66"],
	["/de/05-residenz-am-balmer-see-bs_67", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS67"],
	["/de/05-residenz-am-balmer-see-bs_68", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS68"],
	["/de/05-residenz-am-balmer-see-bs_69", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS69"],
	["/de/05-residenz-am-balmer-see-bs_70", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS70"],
	["/de/05-residenz-am-balmer-see-bs_71", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS71"],
	["/de/07-ferienwohnung-am-achterwasser-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Achterwasser-.2"],
	["/de/07-ferienwohnung-biene", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Biene"],
	["/de/07-ferienwohnung-hering", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Hering"],
	["/de/07-ferienwohnung-ostseestern", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Ostseestern-.Ueckeritz"],
	["/de/07-ferienwohnung-sonneninsel", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Sonneninsel-.Ueckeritz"],
	["/de/07-haus-stortebeker", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Stoertebeker-.Ueckeritz"],
	["/de/07-uckeritz-fischerblick", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Fischerblick-.Ueckeritz"],
	["/de/08-fischerhus-mit-sauna-und-kamin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Fischerhus"],
	["/de/08-fritz-reethus-mit-sauna-und-kamin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Fritz.Reethus"],
	["/de/08-haus-berlin-krabbengalerie", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Berlin-.Krabbengalerie"],
	["/de/08-haus-berlin-loddiner-hoft", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Berlin-.Loddiner.Hoeft"],
	["/de/08-haus-berlin-wohnung-02", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Berlin-.Whg-.2"],
	["/de/08-haus-ostseegluck-wohnung-abendsonne", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Abendsonne"],
	["/de/08-haus-ostseegluck-wohnung-mittagssonne", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Mittagssonne"],
	["/de/08-haus-ostseegluck-wohnung-morgensonne", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Morgensonne"],
	["/de/08-haus-ostseegluck-wohnung-ostseesonne", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Ostseesonne"],
	["/de/08-heide-hus-usedom-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Heide.Hus"],
	["/de/08-hygge-hus-loddin-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Hygge.Hus"],
	["/de/08-loddin-ferienhaus-achterwasserblick-we-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Achterwasserblick-.01"],
	["/de/08-loddin-ferienhaus-achterwasserblick-we-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Achterwasserblick-.02"],
	["/de/08-loddin-ferienhaus-achterwasserblick-we-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Achterwasserblick-.04"],
	["/de/08-loddinperle-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Loddinperle"],
	["/de/08-lodino-lodge-mit-sauna-und-kamin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Lodino.Lodge"],
	["/de/08-reethus-54-degree-loddin-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Reethus.54-"],
	["/de/09-ferienhaus-matthies", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/Matthies-.Ferienhaus"],
	["/de/09-fuurtoorn-mit-fass-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Fueuertoorn"],
	["/de/09-kleiner-wagen-stubbenfelde", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Kleiner.Wagen"],
	["/de/09-schwalbennest", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/Schwalbennest"],
	["/de/09-seehaus-lutt-marten", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Seehaus.Luett.Marten"],
	["/de/09-seehaus-sot-mine", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Seehaus.Soet.Mine"],
	["/de/09-strandkorv-mit-fass-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Strandkoerv"],
	["/de/09-strandperle", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/Strandperle-.Koelpinsee"],
	["/de/09-villa-daheim-01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.01"],
	["/de/09-villa-daheim-02", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.02"],
	["/de/09-villa-daheim-03", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.03"],
	["/de/09-villa-daheim-04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.04"],
	["/de/09-villa-daheim-05", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.05"],
	["/de/09-villa-daheim-06", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.06"],
	["/de/09-villa-daheim-07", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.07"],
	["/de/10-bernsteinquartier-bq0108-mister-bernstein", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0108"],
	["/de/10-bernsteinquartier-bq0225", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0225"],
	["/de/10-bernsteinquartier-bq0440-apartment-mit-infrarotsauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0440"],
	["/de/10-bernsteinquartier-bq0446-movennest", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0446"],
	["/de/10-bernsteinquartier-bq0447", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0447"],
	["/de/10-bernsteinquartier-bq0548", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0548"],
	["/de/10-bernsteinquartier-bq0549-feriengluck", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0549"],
	["/de/10-bernsteinquartier-bq0550", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0550"],
	["/de/10-bernsteinquartier-bq0551", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0551"],
	["/de/10-bernsteinquartier-bq0553", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0553"],
	["/de/10-bernsteinquartier-bq0554-lieblingsplatz", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0554"],
	["/de/10-bernsteinquartier-bq0555", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0555"],
	["/de/10-bernsteinquartier-bq0556-meeresgold", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0556"],
	["/de/10-bernsteinquartier-bq0557-bonjour", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0557"],
	["/de/10-bernsteinquartier-bq0558-eichenkrone", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0558"],
	["/de/10-bernsteinquartier-bq0559", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0559"],
	["/de/10-bernsteinquartier-bq0561-ostseeliegeplatz61", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0561"],
	["/de/10-bernsteinquartier-bq06b-haus-lachmowe", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ06b"],
	["/de/10-bernsteinquartier-bq06e", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ06e"],
	["/de/10-bernsteinquartier-bq07b-ferienhaus-windspiel", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ07b"],
	["/de/10-bernsteinquartier-bq07e-ferienhaus-meerzeit", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ07e"],
	["/de/10-bernsteinquartier-bq08c-haus-nordhorn", "/s"],
	["/de/10-bernsteinquartier-bq09a-ferienhaus-meeresrauschen", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ09a"],
	["/de/10-bernsteinquartier-bq09b-ostseeparadies", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ09b"],
	["/de/10-bernsteinquartier-bq09c-ostseenest", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ09c"],
	["/de/10-bernsteinquartier-bq10b", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ10b"],
	["/de/10-bernsteinquartier-bq10c", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ10c"],
	["/de/10-bernsteinquartier-bq1182", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1182"],
	["/de/10-bernsteinquartier-bq1184-ferienwohnung-strandlust", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1184"],
	["/de/10-bernsteinquartier-bq1186", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1186"],
	["/de/10-bernsteinquartier-bq1188", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1188"],
	["/de/10-bernsteinquartier-bq1190", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1190"],
	["/de/11-ferienwohnung-aurum", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Aurum"],
	["/de/11-ferienwohnung-maus", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Maus"],
	["/de/11-koserow-villa-ostseestrand", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Ostseestrand-.Villa"],
	["/de/11-seeblick_221", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Seeblick-.221"],
	["/de/11-seeblick_222", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Seeblick-.222"],
	["/de/11-seeblick_224", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Seeblick-.224"],
	["/de/11-waterblick_2-01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Waterblick-.201"],
	["/de/12-bungalow-sonneninsel", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Sonneninsel-.Zempin"],
	["/de/12-ferienwohnung-jannek", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Jannek"],
	["/de/12-ferienwohnung-seestrasse", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Seestrasse-.FeWo"],
	["/de/12-residenz-waldhaus-whg-05", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Waldhaus-.05"],
	["/de/12-villa-inselliebe-mit-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Inselliebe"],
	["/de/12-villa-kiesel", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Kiesel"],
	["/de/12-villa-maarten", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Maarten"],
	["/de/13-ferienwohnung-ahorn", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ahorn"],
	["/de/13-ferienwohnung-blei", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Blei"],
	["/de/13-ferienwohnung-dohner-wohnung-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.01"],
	["/de/13-ferienwohnung-dohner-wohnung-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.02"],
	["/de/13-ferienwohnung-dohner-wohnung-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.03"],
	["/de/13-ferienwohnung-dohner-wohnung-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.04"],
	["/de/13-ferienwohnung-dune-25-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Duene.25-.1"],
	["/de/13-ferienwohnung-gunsilius", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Gunsilius"],
	["/de/13-ferienwohnung-sonne", "/s"],
	["/de/13-ferienwohnung-strand-8", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Strand-.8"],
	["/de/13-hansehus-whg-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.01"],
	["/de/13-hansehus-whg-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.02"],
	["/de/13-hansehus-whg-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.03"],
	["/de/13-hansehus-whg-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.04"],
	["/de/13-hansehus-whg-5", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.05"],
	["/de/13-haus-seeadler-whg-habicht", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Seeadler-.Habicht"],
	["/de/13-haus-seeadler-whg-seeschwalbe", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Seeadler-.Seeschwalbe"],
	["/de/13-meerferienhaus-haus-schlehenweg", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Schlehenweg-.Ferienhaus"],
	["/de/13-meerferienhaus-leselust", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Leselust"],
	["/de/13-meerferienhaus-wellnesshaus", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Wellnesshaus"],
	["/de/13-ostseequartett-casa-baltica", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Casa.Baltica"],
	["/de/13-ostseequartett-s-tr-and-im-schuh-wohnung-24", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Whg-24.S-tr-and-im-Schuh"],
	["/de/13-ostseequartett-whg-mowennest", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Moewennest"],
	["/de/13-ostseequartett-wohnung-7", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Whg-.7"],
	["/de/13-villa-lohengrin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Lohengrin"],
	["/de/13-villa-tusculum-whg-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Tusculum-.01"],
	["/de/13-villa-tusculum-whg-possin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Tusculum-.Possin"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.1"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.2"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.3"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-4", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.4"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-5", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.5"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-7", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.7"],
	["/de/13-zinnowitzer-hof-ferienwohnung-kustenwald-wohnung-04", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Zinnowitzer.Hof-.Kuestenwald"],
	["/de/13-zinnowitzer-hof-ferienzimmer-kustensonne-wohnung-03", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Zinnowitzer.Hof-.Kuestensonne"],
	["/de/15-bella-vita", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Bella.Vita"],
	["/de/15-bungalow-jonas", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Jonas"],
	["/de/15-bungalow-konrad", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Konrad"],
	["/de/15-ferienhaus-brakkelmann-birkenhain", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Brakkelmann"],
	["/de/15-ferienhaus-zur-frischen-brise-whg-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Frische.Brise-.01"],
	["/de/15-ferienhaus-zur-frischen-brise-whg-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Frische.Brise-.02"],
	["/de/15-ferienwohnung-carlotta", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Carlotta"],
	["/de/15-ferienwohnung-eichhorst-whg-3", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Eichhorst-.03"],
	["/de/15-ferienwohnung-emilia", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Emilia"],
	["/de/15-sonnenschein-leuchtfeuer", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Sonnenschein-.Leuchtfeuer"],
	["/de/15-sonnenschein-seekiste", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Sonnenschein-.Seekiste"],
	["/de/15-sonnenschein-seepferdchen", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Sonnenschein-.Seepferdchen"],
	["/de/16-ferienhaus-am-eikboom-1-mit-fass-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Eikboom-.01"],
	["/de/16-ferienhaus-am-eikboom-2-mit-fass-sauna", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Eikboom-.02"],
	["/de/16-ferienhaus-haus-zum-kapitan", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Kapitaen-.Ferienhaus"],
	["/de/16-ferienhaus-karl-mit-sauna-elysium", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Karl"],
	["/de/16-ferienhaus-krabbe", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Krabbe"],
	["/de/16-ferienhaus-rosa-mit-sauna-elysium", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Rosa"],
	["/de/16-ferienhaus-seestern", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Seestern"],
	["/de/16-ferienhaus-waldoase-1", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Waldoase-.01"],
	["/de/16-ferienhaus-waldoase-2", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Waldoase-.02"],
	["/de/16-haus-viktoria-am-see", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Viktoria"],
	["/de/ferieninsel-usedom/", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom"],
	["/de/ferienwohnungen/", "/s"],
	["/de/merkliste", "/s#?favorites=true"],
	["/de/unser-service/", "/service-fur-unsere-gaste"],
	// ["/ferienhaus-usedom-privat.html", "/indexpage"],
	["/ferieninsel-usedom/", "/sehenswertes"],
	["/ferieninsel-usedom/events-und-kultur.html", "/events-and-kultur"],
	["/ferieninsel-usedom/golfurlaub-auf-usedom.html", "/golfurlaub-auf-usedom"],
	["/ferieninsel-usedom/sehenswertes.html", "/sehenswertes"],
	["/ferieninsel-usedom/sport-und-erholung.html", "/sport-und-erholung"],
	["/ferienwohnung-bansin.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin"],
	["/ferienwohnung-usedom-ostsee-meerblick", "/indexpage"],
	["/ferienwohnungen/", "/s"],
	["/ferienwohnungen/ahlbeck.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck"],
	["/ferienwohnungen/balm.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm"],
	["/ferienwohnungen/bansin.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin"],
	["/ferienwohnungen/bernsteinquartier-koelpinsee.html", "/s"],
	["/ferienwohnungen/ferieninsel-usedom/", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom"],
	["/ferienwohnungen/ferienwohnungen/", "/s"],
	["/ferienwohnungen/haeuser-in-loddin.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin"],
	["/ferienwohnungen/heringsdorf.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf"],
	["/ferienwohnungen/karlshagen.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen"],
	["/ferienwohnungen/koelpinsee.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee"],
	["/ferienwohnungen/koserow.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow"],
	["/ferienwohnungen/online-buchen.html", "/s"],
	["/ferienwohnungen/online-buchen.html/de/ferieninsel-usedom", "/s"],
	["/ferienwohnungen/online-buchen.html/de/ferienwohnungen", "/s"],
	["/ferienwohnungen/online-buchen.html/de/unser-service", "/service-fur-unsere-gaste"],
	["/ferienwohnungen/stubbenfelde.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde"],
	["/ferienwohnungen/trassenheide.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide"],
	["/ferienwohnungen/ueckeritz.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz"],
	["/ferienwohnungen/unser-service/", "/service-fur-unsere-gaste"],
	["/ferienwohnungen/zempin.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin"],
	["/ferienwohnungen/zinnowitz.html", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz"],
	["/impressum.html", "/impressum"],
	["/kontakt.html", "/kontakt"],
	["/kontakt/", "/kontakt"],
	["/unser-service/", "/service-fur-unsere-gaste"],
	["/unser-service/downloads.html", "/service-fur-unsere-gaste"],
	["/unser-service/fragen-und-antworten.html", "/faq"],
	["/unser-service/fuer-eigentmer-von-ferienimmobilien.html", "/fur-eigentumer"],
	["/unser-service/gaeste.html", "/faq"],
	["/usedom.html", "/ueber-uns"],
	["/myusedom24-gmbh", "/"],
	["/unser-service/anreiseformular", "/service-fur-unsere-gaste"],
	["/de/ostseeresidenz-gorki-park-gp_09", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP09"],
	["/de/zka_ferienhaus-am-eikboom-dhh01", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Eikboom-.01"],
	["/de/ahlb_villa-bellevue-app-8", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bellevue-.08"],
	["/de/reethus-54-degree-loddin", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Reethus.54-"],
]);





// console.log("pathname", pathname);
// console.log("redirects", redirects);
// console.log("redirects.has(pathname)", redirects.has(pathname));


// Only use pathname for matching, ignore query params
if (redirects.has(pathname)) {
	location.href = origin + redirects.get(pathname);
}

// Check if the current URL (pathname + search) matches any key in the redirects map
/* const currentPath = pathname + search;

if (redirects.has(currentPath)) {
	// Redirect to the new path without the search parameters
	location.href = origin + redirects.get(currentPath);
} */